import React, { Fragment, useState } from 'react'

export default function Feedback(props) {
  const stars = [0, 1, 2, 3, 4]

  const [form, setForm] = useState({
    fullName: {
      key: 'fullName',
      label: 'Full Name',
      required: true,
      description:
        'Please provide us with your name in case we need to follow up and assist',
      value: '',
      type: 'text',
    },
    satisfaction: {
      key: 'satisfaction',
      label: 'Were you satisfied with the service received from us overall?',
      required: false,
      description: 'Please rate us: 1 Star = POOR, 5 stars = EXCELLENT',
      value: '',
      type: 'star',
    },
    commentsAndCompliments: {
      key: 'commentsAndCompliments',
      label: 'Do you have any comments on how we can improve our service?',
      required: false,
      description: 'Comments and Compliments',
      value: '',
      type: 'textarea',
    },
  })
  const [question, setQuestion] = useState(0)
  const [hoverRating, setHoverRating] = useState(-1)

  const setText = (key, event) => {
    const prevF = JSON.parse(JSON.stringify(form))
    prevF[key].value = event.target.value
    setForm(prevF)
  }

  const setStar = (key, star) => {
    const prevF = JSON.parse(JSON.stringify(form))
    prevF[key].value = star.toString()
    setForm(prevF)
    setHoverRating(-1)
    setTimeout(() => {
      setQuestion(question + 1)
    }, 500)
  }

  const starRating = (rating) => {
    try {
      return parseInt(rating)
    } catch (error) {
      return 0
    }
  }

  const prevNext = (event, up) => {
    event.preventDefault()
    if (up && question !== Object.keys(form).length - 1) {
      setQuestion(question + 1)
    }
    if (!up && question !== 0) {
      setQuestion(question - 1)
    }
  }

  return (
    <>
      <div className="w-100 h-100">
        <div className="grid form-grid h-100">
          {props.formSent ? (
            <div className="ph4 grs1 gre2 self-center">
              <span className="fw5 w-100 misty-blue-mid">
                Your feedback was successfully sent, thank you!
              </span>
            </div>
          ) : (
            [form[Object.keys(form)[question]]].map((q, i) => (
              <Fragment key={i}>
                <div className="ph4 grs1 gre2 self-center">
                  <span className="fw5 w-100 misty-blue-mid">
                    Question {question + 1} / {Object.keys(form).length}
                  </span>
                  <form
                    className="w-100 h-100 mt2"
                    onSubmit={(e) => {
                      e.preventDefault()
                      if (question < Object.keys(form).length - 1) {
                        console.log('next q')
                        setQuestion(question + 1)
                      } else {
                        console.log(form)
                        const formout = {fullName: form.fullName.value, satisfaction: form.satisfaction.value, commentsAndCompliments: form.commentsAndCompliments.value}
                        props.submitForm(formout)
                      }
                    }}
                  >
                    {q.type === 'star' ? (
                      <div className="w-100 pb3 fw5">
                        {q.label}
                        {q.required ? '*' : ''}
                      </div>
                    ) : null}
                    <label>
                      {q.description}
                      {q.type === 'star' ? (
                        <div
                          className="gc5 grid mv4"
                          onMouseLeave={(e) => setHoverRating(-1)}
                        >
                          {stars.map((s) => (
                            <div
                              key={'s' + s}
                              className={`gcs${s + 1} gce${
                                s + 2
                              } f2 center pointer dim misty-blue-mid`}
                              onClick={(e) => setStar(q.key, s + 1)}
                              onMouseOver={(e) => setHoverRating(s + 1)}
                            >
                              {hoverRating > 0
                                ? hoverRating >= s + 1
                                  ? '★'
                                  : '☆'
                                : starRating(q.value) >= s + 1
                                ? '★'
                                : '☆'}
                            </div>
                          ))}
                        </div>
                      ) : q.type === 'text' ? (
                        <input
                          className="mv4 w-100 pa2-ns"
                          type="text"
                          required={q.required}
                          placeholder={`${q.label}${q.required ? '*' : ''}`}
                          name={Object.keys(form)[question]}
                          value={q.value}
                          onChange={(event) => setText(q.key, event)}
                        />
                      ) : (
                        <textarea
                          className="mv3 w-100 pa3-ns"
                          required={q.required}
                          placeholder={`${q.label}${q.required ? '*' : ''}`}
                          name={Object.keys(form)[question]}
                          value={q.value}
                          onChange={(event) => setText(q.key, event)}
                        />
                      )}
                    </label>
                    <input
                      type="submit"
                      className="bg-misty-blue-mid white br2 bn pv2 ph4 dim pointer center db shadow-2"
                      value={
                        question < Object.keys(form).length - 1
                          ? 'Enter'
                          : 'Submit'
                      }
                    />
                  </form>
                </div>
                <div className="grid gc2 ph4 grs2 gre3 pt3">
                  <button
                    disabled={question === 0}
                    onClick={(e) => prevNext(e, false)}
                    className="bg-misty-blue-mid white br2 bn pa2 mr2 dim pointer shadow-2"
                  >
                    Previous
                  </button>
                  <button
                    disabled={
                      question === Object.keys(form).length - 1 ||
                      (q.required && q.value === '')
                    }
                    onClick={(e) => prevNext(e, true)}
                    className="bg-misty-blue-mid white br2 bn pa2 ml2 dim pointer shadow-2"
                  >
                    Next
                  </button>
                </div>
              </Fragment>
            ))
          )}
        </div>
      </div>
    </>
  )
}
