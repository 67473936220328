import React, { useState } from 'react'
import axios from 'axios'
import Feedback from './components/feedback'

const App = () => {
  const search = window.location.search
  const task = new URLSearchParams(search).get('task')
  const user = new URLSearchParams(search).get('user')
  const page = new URLSearchParams(search).get('page') || 'feedback'

  const [formSent, setFormSent] = useState(false)

  console.log(page, user, task)

  const sendForm = (form) => {
    console.log(form)
    if (user === undefined || task === undefined) {
      alert('unknown parameter')
    } else {
      const headers = {
        'Content-Type': 'application/json',
      }
      axios
        .post(
          'https://app.remind.works/feedback',
          {
            messageId: 'gui',
            task: task,
            user: user,
            feedback: JSON.stringify(form),
            text: ''
          },
          { headers }
        )
        .then((response) => {
          console.log(response)
          setFormSent(true)
        })
        .catch((err) => {
          console.log(err)
          alert(err)
        })
    }
  }

  return (
    <div className="avenir w-100 h-100 rw-grid grid smallminheight smallminheight-ns">
      <div className="gcs1 gce2 grs1 gre2 pa2 bg-misty-blue-mid white shadow-2">
        <div className="mw7 center">
          RemindWorks {page === 'feedback' ? 'Feedback' : 'Booking'}
        </div>
      </div>
      <div className="gcs1 gce2 grs2 gre3 mw7 center">
        {page === 'feedback' ? (
          <Feedback submitForm={(form) => sendForm(form)} formSent={formSent} />
        ) : null}
      </div>
    </div>
  )
}

export default App
